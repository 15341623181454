import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card } from '@knack/asterisk-react';

import { type Account } from '@/types/account';
import { BILLING_PAGE_TABS } from '@/hooks/usePageTabs';
import { formatNumberForStorage } from '@/utils/formatters';

interface PlanUsageProps {
  accountData: Account;
  cardTitleClasses?: string;
}

export function PlanUsage({ accountData, cardTitleClasses }: PlanUsageProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const isStarter = accountData?.product_plan?.id.includes('starter') ?? false;

  const {
    appsLimit,
    isHipaa,
    isInternalAccount,
    isPrivatePlan,
    isTrial,
    numberOfApps,
    numberOfRecords,
    recordsLimit,
    storageLimit,
    storageLimitInBytes,
    storagePercent,
    storageUsedInBytes,
    totalDailyApiLimit
  } = accountData;

  return (
    <Card className="flex flex-col p-6 sm:p-6" data-testid="plan-usage-apps">
      <h2 className={cardTitleClasses}>{t('components.billing.overview.plan_usage')}</h2>
      <div className="mb-6">
        <span className="mb-1 block text-sm text-subtle">{t('components.apps.apps')}</span>
        {isPrivatePlan && <p>{numberOfApps}</p>}

        {!isPrivatePlan && numberOfApps > appsLimit && (
          <div className="text-destructive">
            <p className="mb-1 font-medium">
              {t('components.billing.overview.apps_amount_available', {
                n: numberOfApps.toLocaleString(),
                total: appsLimit.toLocaleString()
              })}
            </p>
            <p className="text-sm">{t('components.billing.overview.apps_exceeding')}</p>
          </div>
        )}

        {!isPrivatePlan && !(numberOfApps > appsLimit) && (
          <p>
            {t('components.billing.overview.apps_amount_available', {
              n: numberOfApps.toLocaleString(),
              total: appsLimit.toLocaleString()
            })}
          </p>
        )}
      </div>
      <div className="mb-6" data-testid="plan-usage-records">
        <span className="mb-1 block text-sm text-subtle">
          {t('components.billing.overview.records')}
        </span>
        {isPrivatePlan && <p>{numberOfRecords.toLocaleString()}</p>}

        {!isPrivatePlan && numberOfRecords > recordsLimit && (
          <div className="text-destructive">
            <p className="mb-1 font-medium">
              {t('components.billing.overview.records_amount_available', {
                n: numberOfRecords.toLocaleString(),
                total: recordsLimit.toLocaleString()
              })}
            </p>
            <p className="text-sm">{t('components.billing.overview.records_exceeding')}</p>
          </div>
        )}

        {!isPrivatePlan && !(numberOfRecords > recordsLimit) && (
          <p>
            {t('components.billing.overview.records_amount_available', {
              n: numberOfRecords.toLocaleString(),
              total: recordsLimit.toLocaleString()
            })}
          </p>
        )}
      </div>
      <div className="mb-6" data-testid="plan-usage-storage">
        <span className="mb-1 block text-sm text-subtle">
          {t('components.billing.overview.storage')}
        </span>
        {isPrivatePlan && <p>{storageUsedInBytes}</p>}

        {!isPrivatePlan && storageUsedInBytes > storageLimitInBytes && (
          <div className="text-destructive">
            <p className="mb-1 font-medium">
              {t('components.billing.overview.storage_amount_available', {
                percentage: storagePercent,
                kb: formatNumberForStorage(storageUsedInBytes),
                total: storageLimit
              })}
            </p>
            <p className="text-sm">{t('components.billing.overview.storage_exceeding')}</p>
          </div>
        )}

        {!isPrivatePlan && !(storageUsedInBytes > storageLimitInBytes) && (
          <p>
            {t('components.billing.overview.storage_amount_available', {
              percentage: storagePercent,
              kb: formatNumberForStorage(storageUsedInBytes),
              total: storageLimit
            })}
          </p>
        )}
      </div>
      <div className="mb-6">
        <span className="mb-1 block text-sm text-subtle">
          {t('components.billing.overview.api_calls')}
        </span>
        <p className="mb-1">
          {t('components.billing.overview.api_addon_daily_limit_total', {
            numberOfCalls: totalDailyApiLimit.toLocaleString()
          })}
        </p>
        <p className="text-sm text-subtle">
          {t('components.billing.overview.api_addon_daily_limit_more_info')}
        </p>
      </div>
      {!isTrial && !isInternalAccount && !isStarter && (
        <div className="mt-auto flex">
          <Button
            intent="secondary"
            size="sm"
            disabled={isHipaa}
            className="grow"
            onClick={() =>
              navigate({
                pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.AddOns.path}`,
                search
              })
            }
          >
            {t('components.billing.overview.purchase_addons')}
          </Button>
        </div>
      )}
    </Card>
  );
}
