import { useState } from 'react';

import { Route } from '@/enums/routing';

interface PageTab {
  path: string;
  label: string;
  dataTestId?: string;
}

export interface PageTabs {
  baseUrl: string;
  tabs: Record<string, PageTab>;
}

export const APPS_PAGE_TABS: PageTabs = {
  baseUrl: `/${Route.Apps}`,
  tabs: {
    MyApps: {
      path: '',
      label: 'components.apps.my_apps',
      dataTestId: 'apps-my-apps-tab'
    },
    SharedApps: {
      path: Route.SharedApps,
      label: 'components.apps.shared_apps',
      dataTestId: 'apps-shared-apps-tab'
    }
  }
};

export const ACCOUNT_SETTINGS_PAGE_TABS: PageTabs = {
  baseUrl: `/${Route.Settings}`,
  tabs: {
    Overview: {
      path: Route.Account,
      label: 'components.account_settings.overview',
      dataTestId: 'account-tabs-overview-tab'
    },
    Management: {
      path: `${Route.Account}/${Route.Management}`,
      label: 'components.account_settings.management',
      dataTestId: 'account-tabs-management-tab'
    }
  }
};

export const BILLING_PAGE_TABS_HIPAA: PageTabs = {
  baseUrl: `/${Route.Settings}/${Route.Billing}`,
  tabs: {
    Overview: {
      path: '',
      label: 'components.billing.overview.tab'
    },
    Payment: {
      path: Route.Payment,
      label: 'components.billing.payment.tab'
    },
    Invoices: {
      path: Route.Invoices,
      label: 'components.billing.invoices.tab'
    }
  }
};

export const BILLING_PAGE_TABS_TRIAL: PageTabs = {
  baseUrl: `/${Route.Settings}/${Route.Billing}`,
  tabs: {
    Overview: {
      path: '',
      label: 'components.billing.overview.tab',
      dataTestId: 'plan-tabs-overview-tab'
    },
    Plans: {
      path: Route.Plans,
      label: 'components.billing.plans.tab',
      dataTestId: 'plan-tabs-plans-tab'
    }
  }
};

export const BILLING_PAGE_TABS_STARTER: PageTabs = {
  baseUrl: `/${Route.Settings}/${Route.Billing}`,
  tabs: {
    ...BILLING_PAGE_TABS_TRIAL.tabs,
    Payment: {
      path: Route.Payment,
      label: 'components.billing.payment.tab',
      dataTestId: 'plan-tabs-payment-tab'
    },
    Invoices: {
      path: Route.Invoices,
      label: 'components.billing.invoices.tab',
      dataTestId: 'plan-tabs-invoices-tab'
    }
  }
};

export const BILLING_PAGE_TABS: PageTabs = {
  baseUrl: `/${Route.Settings}/${Route.Billing}`,
  tabs: {
    ...BILLING_PAGE_TABS_TRIAL.tabs,
    AddOns: {
      path: Route.AddOns,
      label: 'components.billing.addons.tab',
      dataTestId: 'plan-tabs-addons-tab'
    },
    Payment: {
      path: Route.Payment,
      label: 'components.billing.payment.tab',
      dataTestId: 'plan-tabs-payment-tab'
    },
    Invoices: {
      path: Route.Invoices,
      label: 'components.billing.invoices.tab',
      dataTestId: 'plan-tabs-invoices-tab'
    }
  }
};

export function usePageTabs(initialTabs: PageTabs) {
  const [tabConfig, setTabConfig] = useState(initialTabs);

  return { tabConfig, setTabConfig };
}
