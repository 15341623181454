import { Tooltip } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

interface CellTooltipProps {
  isIconButton?: boolean;
  text?: string;
  children?: React.ReactNode;
}

function CellTooltip({ isIconButton, text, children }: CellTooltipProps) {
  return (
    <Tooltip>
      <Tooltip.Trigger asChild>
        <div
          className={cn(
            'truncate align-middle',
            isIconButton ? 'w-auto' : 'min-w-full max-w-[40ch]'
          )}
        >
          {children}
        </div>
      </Tooltip.Trigger>
      <Tooltip.Content className="whitespace-normal text-center">
        {text ?? children}
      </Tooltip.Content>
    </Tooltip>
  );
}

export { CellTooltip };
