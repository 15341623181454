import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@knack/asterisk-react';

import { useNextRandomFromArray } from '@/hooks/useNextRandomFromArray';

type Suggestion = { summary: string; query: string };

export function AiSuggestions({ onClick }: { onClick: (query: string) => void }) {
  const { t } = useTranslation();
  const [suggestion, setSuggestion] = useState<Suggestion>({
    summary: '',
    query: ''
  });
  const suggestions = useMemo<Record<string, Suggestion>>(
    () =>
      t('components.create_app.ai.suggestions', { returnObjects: true }) as Record<
        string,
        Suggestion
      >,
    [t]
  );
  const { next } = useNextRandomFromArray(Object.keys(suggestions));
  const onUpdateSuggestion = useCallback(() => {
    setSuggestion(suggestions[next()]);
  }, [suggestions, next]);

  useEffect(() => {
    onUpdateSuggestion();
  }, [suggestions]);

  if (!suggestion) {
    return undefined;
  }

  return (
    <div
      className="mb-2 flex w-full justify-end gap-2"
      data-testid="create-app-ai-query-suggestions"
    >
      <Button
        autoFocus={false}
        className="text-gradient-1 hover:text-gradient-2 dark:text-gradient-2 dark:hover:text-gradient-1 focus:text-gradient-2 dark:focus:text-gradient-1 pointer-events-auto h-auto px-1 py-0 text-left !outline-none"
        intent="minimalStandalone"
        onClick={() => {
          onClick(suggestion.query);
          onUpdateSuggestion();
        }}
        style={{ pointerEvents: 'all' }}
        data-testid="create-app-ai-query-suggestions-use"
      >
        <span data-testid="create-app-ai-query-suggestions-suggestion">
          {t('components.create_app.ai.suggest_a_prompt')}
        </span>
      </Button>
    </div>
  );
}
