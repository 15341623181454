import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
  type DragStartEvent,
  type UniqueIdentifier
} from '@dnd-kit/core';
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Dialog } from '@knack/asterisk-react';

import { type KnackApplication } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { cn } from '@/utils/tailwind';
import { AppsDialogContent } from '@/components/ui/AppsDialogContent';
import { AppItem } from '@/pages/apps/apps-table';
import { type DisablingRestrictions } from '@/pages/apps/AppsPage';

interface AppsTableProps {
  apps: KnackApplication[];
  sharedAccountSlug?: string;
  isSharedAppsTable?: boolean;
  restrictions?: DisablingRestrictions;
}

export type ActiveRow = UniqueIdentifier | null;

export interface SelectedItem {
  app: KnackApplication;
  type: SelectedItemType;
}

export enum SelectedItemType {
  Settings = 'settings',
  ManageBuilders = 'manage-builders',
  Duplicate = 'duplicate',
  Delete = 'delete',
  DeleteAssets = 'delete-assets',
  TasksQuick = 'tasks-quick-view',
  DeleteBuilder = 'delete-builder'
}

function AppsTable({
  apps,
  isSharedAppsTable = false,
  sharedAccountSlug,
  restrictions
}: AppsTableProps) {
  const [activeRow, setActiveRow] = useState<ActiveRow>(null);
  const [dataApps, setDataApps] = useState<KnackApplication[]>();
  const [t] = useTranslation();
  const [selectedItem, setSelectedItem] = useState<SelectedItem | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { sortApps } = useAppMutation();

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };

  const commonCellClasses = 'table-cell p-2 border-b border-subtle';

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 300,
        tolerance: 8
      }
    })
  );

  useEffect(() => {
    setDataApps(apps);
  }, [apps]);

  function handleSortApps(applications: KnackApplication[]) {
    if (applications) {
      const newAppOrder = applications.map((app: KnackApplication) => app.id);
      sortApps.mutate(newAppOrder);
    }
  }

  function handleDragStart(event: DragStartEvent) {
    const { active } = event;
    setActiveRow(active.id);
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      setDataApps((items) => {
        if (!items) {
          return items;
        }
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newSortOrder = arrayMove(items, oldIndex, newIndex);

        handleSortApps(newSortOrder);

        return newSortOrder;
      });
    }
    setActiveRow(null);
  }

  return (
    <Dialog open={isDialogOpen} onOpenChange={setDialogOpen}>
      <div className="w-full overflow-x-auto">
        <div
          className="mb-4 table w-full border-collapse text-left text-default"
          data-testid="apps-table"
        >
          <div className="table-row border-b text-sm font-medium md:border-b-0">
            {!isSharedAppsTable && dataApps && dataApps.length > 0 && (
              <div className="table-cell w-1 p-2">
                <span className="hidden">Handle</span>
              </div>
            )}
            <div
              className={cn(commonCellClasses, 'w-auto max-w-[40ch] overflow-hidden truncate')}
              data-testid="apps-table-cells"
            >
              {t('components.apps.name')}
            </div>{' '}
            <div
              className={cn(commonCellClasses, 'w-[5ch] text-right')}
              data-testid="apps-table-cells"
            >
              {t('components.apps.records')}
            </div>{' '}
            <div
              className={cn(commonCellClasses, 'w-[5ch] text-right')}
              data-testid="apps-table-cells"
            >
              {t('components.apps.storage')}
            </div>{' '}
            <div
              className={cn(commonCellClasses, 'w-[5ch] text-right')}
              data-testid="apps-table-cells"
            >
              {t('components.apps.tasks')}
            </div>{' '}
            <div
              className={cn(commonCellClasses, 'w-auto max-w-[40ch] overflow-hidden truncate')}
              data-testid="apps-table-cells"
            >
              {t('components.apps.description')}
            </div>{' '}
            <div className={cn(commonCellClasses, 'w-0')} data-testid="apps-table-cells">
              {t('components.apps.actions')}
            </div>{' '}
          </div>
          <div className="h-2" />
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={(e) => handleDragStart(e)}
            onDragEnd={(e) => handleDragEnd(e)}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext items={dataApps || []} strategy={verticalListSortingStrategy}>
              {dataApps?.map((app: KnackApplication) => (
                <AppItem
                  key={app.id}
                  app={app}
                  activeRow={activeRow}
                  setSelectedItem={setSelectedItem}
                  isSharedAppsTable={isSharedAppsTable}
                  sharedAccountSlug={sharedAccountSlug}
                  restrictions={restrictions}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
      <AppsDialogContent selectedItem={selectedItem} handleDialogClose={handleDialogClose} />
    </Dialog>
  );
}
export { AppsTable };
