import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useLocalStorage } from 'usehooks-ts';
import { z } from 'zod';

import { type Credentials } from '@/types/auth';
import { type Session } from '@/types/session';
import { queryKeys } from '@/hooks/api/queryKeys';
import { LOCAL_STORAGE_KEYS } from '@/utils/constants';

type SignInResponse = {
  success: boolean;
  session: Session;
  message: string;
};

async function signIn(credentials: Credentials) {
  const { data } = await axios.post<SignInResponse>(
    `/v1/dashboard/accounts/sign-in`,
    { ...credentials, totp_token: credentials.totp_token?.toString() },
    { withCredentials: true }
  );

  return data;
}

export function usePostSignInActions() {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const [isNewUser, setIsNewUser] = useLocalStorage(LOCAL_STORAGE_KEYS.isNewUser, true);

  return (session: Session) => {
    // Set `isNewUser` in localStorage to `false` after a user successfully signs in for the first time using their current browser.
    // This flag is never changed or cleared once it's set to `false`, unless it's manually cleared from localStorage by the user.
    if (isNewUser) {
      setIsNewUser(false);
    }

    // If a user originally tries to access the builder but is redirected to the dashboard due to being unauthenticated, the builder will pass
    // the original builder pathname in a query param called `builderPathname` so we can redirect the user back to it after they login
    const params = new URLSearchParams(search);
    const builderPathname = params.get('builderPathname');
    if (builderPathname) {
      const url = `${import.meta.env.PUBLIC_BUILDER_URL}${decodeURIComponent(builderPathname)}`;
      const isValidUrl = z.string().url().safeParse(url).success;
      if (isValidUrl) {
        window.location.replace(url);
        return;
      }
    }

    queryClient.setQueryData([queryKeys.auth.session], session);
  };
}

export function useSignInMutation() {
  const runPostSignInActions = usePostSignInActions();
  const [needs2fa, setNeeds2fa] = useState(false);
  const [credentials2fa, setCredentials2fa] = useState<Credentials>({
    email: '',
    password: ''
  });

  return {
    needs2fa,
    credentials2fa,
    ...useMutation({
      mutationFn: signIn,
      onSuccess: (data: SignInResponse, credentials) => {
        if (data.message === 'additional_auth_required') {
          setNeeds2fa(true);
          // Save credentials, so we can make the sign-in call again with the 2fa token
          setCredentials2fa(credentials);
          return;
        }

        runPostSignInActions(data.session);
      }
    })
  };
}
