import { getTimezoneProper } from '@knack/time-helper';
import { DateTime } from 'luxon';

const FLOWS_TIMEZONES_REGIONS = [
  'Hawaii',
  'Alaska',
  'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada)',
  'Central Time (US & Canada)',
  'Eastern Time (US & Canada)',
  'Newfoundland',
  'Santiago',
  'Sao Paulo',
  'Buenos Aires',
  'Greenland',
  'Mid-Atlantic',
  'Azores',
  'Cape Verde Is.',
  'Casablanca',
  'Dublin',
  'Edinburgh',
  'Lisbon',
  'London',
  'Monrovia',
  'Amsterdam',
  'Belgrade',
  'Berlin',
  'Bern',
  'Bratislava',
  'Brussels',
  'Budapest',
  'Copenhagen',
  'Ljubljana',
  'Madrid',
  'Paris',
  'Prague',
  'Rome',
  'Sarajevo',
  'Skopje',
  'Vienna',
  'Warsaw',
  'West Central Africa',
  'Zagreb',
  'Athens',
  'Bucharest',
  'Cairo',
  'Harare',
  'Helsinki',
  'Istanbul',
  'Jerusalem',
  'Kyiv',
  'Pretoria',
  'Riga',
  'Sofia',
  'Tallinn',
  'Vilnius',
  'Baghdad',
  'Kuwait',
  'Minsk',
  'Nairobi',
  'Riyadh',
  'Abu Dhabi',
  'Baku',
  'Moscow',
  'Muscat',
  'St. Petersburg',
  'Tbilisi',
  'Volgograd',
  'Yerevan',
  'Kabul',
  'Islamabad',
  'Karachi',
  'Tashkent',
  'Chennai',
  'Kolkata',
  'Mumbai',
  'New Delhi',
  'Kathmandu',
  'Almaty',
  'Astana',
  'Dhaka',
  'Ekaterinburg',
  'Sri Jayawardenepura',
  'Rangoon',
  'Bangkok',
  'Hanoi',
  'Jakarta',
  'Novosibirsk',
  'Beijing',
  'Chongqing',
  'Hong Kong',
  'Krasnoyarsk',
  'Kuala Lumpur',
  'Perth',
  'Singapore',
  'Taipei',
  'Ulaanbataar',
  'Urumqi',
  'Irkutsk',
  'Osaka',
  'Sapporo',
  'Seoul',
  'Tokyo',
  'Adelaide',
  'Darwin',
  'Brisbane',
  'Canberra',
  'Guam',
  'Hobart',
  'Melbourne',
  'Port Moresby',
  'Sydney',
  'Yakutsk',
  'New Caledonia',
  'Solomon Is.',
  'Vladivostok',
  'Auckland',
  'Fiji',
  'Kamchatka',
  'Magadan',
  'Marshall Is.',
  'Wellington',
  "Nuku'alofa"
];

export const FLOWS_LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'pt', label: 'Portuguese' }
];

const getFlowsTimezoneOffsetInHours = (timezone: string): string => {
  const trueTimezone = getTimezoneProper(timezone);

  const { offset } = DateTime.now().setZone(trueTimezone);

  const sign = offset >= 0 ? '+' : '-';
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60)
    .toString()
    .padStart(2, '0');
  const minutes = (absOffset % 60).toString().padStart(2, '0');

  return `${sign}${hours}:${minutes}`;
};

export const getFlowsTimezones = () =>
  FLOWS_TIMEZONES_REGIONS.map((region) => ({
    label: `(GMT${getFlowsTimezoneOffsetInHours(region)}) ${region}`,
    value: region
  }));
