import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@knack/asterisk-react';
import { useFlagsmith } from 'flagsmith/react';

import { useSession } from '@/hooks/useSession';
import { getSegmentUserTraits, shouldSendAnalytics, trackExperiment } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { getFlagsmithTraitsForSession, getFlagValue } from '@/utils/flagsmith';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { useAppMutation } from './api/mutations/useAppMutation';

export function useCreateFirstApp() {
  const { isCreatingFirstApp, setIsCreatingFirstApp } = usePostSignUpContext();

  const session = useSession();
  const flagsmith = useFlagsmith();
  const { createAppFromSample } = useAppMutation();
  const { presentToast } = useToast();
  const [t] = useTranslation();
  const isLoading = useRef(false);
  const trackEventSent = useRef(false);
  const isAiEnabled = import.meta.env.PUBLIC_ENABLE_AI_APP_CREATION !== 'false';

  const presentErrorToast = (error: Error) => {
    setIsCreatingFirstApp(false);
    isLoading.current = false;

    const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
    presentToast({
      title: message
    });
  };

  const trackVariation = async (
    variationId: 'normal' | 'quick' | 'quick-template' | 'create_with_ai_v1',
    variationName: string
  ) => {
    if (shouldSendAnalytics(session.user.email) && !trackEventSent.current) {
      await trackExperiment(
        {
          experimentId: 'ab_testing_sign_up_flow',
          experimentName: 'AB Testing Sign Up Flow',
          variationId,
          variationName
        },
        session.account.id
      );

      trackEventSent.current = true;
    }
  };

  useEffect(() => {
    if (isCreatingFirstApp && session.user.id && !isLoading.current) {
      isLoading.current = true;

      void (async () => {
        if (session.account.id) {
          await flagsmith.identify(session.account.id, {
            ...getFlagsmithTraitsForSession(session)
          });
        }

        if (session.user.id && shouldSendAnalytics(session.user.email)) {
          const name = `${session.user.firstName} ${session.user.lastName}`;

          await window.analytics.identify(session.user.id, {
            name,
            ...getSegmentUserTraits(session)
          });
        }

        let signUpMode = getFlagValue('ab_testing_sign_up_flow');
        if (signUpMode === 'create_with_ai_v1' && !isAiEnabled) {
          signUpMode = 'normal';
        }

        switch (signUpMode) {
          case 'quick': {
            const sampleApp = {
              appId: '66fac4963bea5b3e05faf2e3',
              templateSlug: 'my-app-name',
              appDescription: 'My App Description',
              appCreatedAutomatically: true
            };
            createAppFromSample.mutate(sampleApp, {
              onSuccess: async (response) => {
                await trackVariation('quick', 'Quick');

                let builderPath = '';
                if (response.app?.objects?.length) {
                  builderPath = `${response.app.slug}/records/objects/object_2`;
                } else {
                  builderPath = `${session.account.slug}/${response.app.slug}`;
                }

                window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${builderPath}`;
              },
              onError: presentErrorToast
            });
            break;
          }
          case 'quick-template': {
            const sampleApp = {
              appId: '653a40e8e4f3c0173a9f2d93',
              templateSlug: 'simple-contact-manager',
              appDescription: 'Simple app to manage contacts',
              appCreatedAutomatically: true
            };
            createAppFromSample.mutate(sampleApp, {
              onSuccess: async (response) => {
                await trackVariation('quick-template', 'Quick Template');

                let builderPath = '';
                if (response.app?.objects?.length) {
                  builderPath = `${response.app.slug}/schema/list`;
                } else {
                  builderPath = `${session.account.slug}/${response.app.slug}`;
                }

                window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${builderPath}`;
              },
              onError: presentErrorToast
            });
            break;
          }
          case 'create_with_ai_v1':
            await trackVariation('create_with_ai_v1', 'Create with AI V1');

            setIsCreatingFirstApp(false);
            isLoading.current = false;
            break;
          case 'normal':
          default:
            await trackVariation('normal', 'Normal');

            setIsCreatingFirstApp(false);
            isLoading.current = false;
            break;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreatingFirstApp, session]);
}
