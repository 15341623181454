import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useLocalStorage } from 'usehooks-ts';

import { useAccountRestrictions } from '@/hooks/account/useAccountRestrictions';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { DISMISS_BANNER_SESSION_KEY, RESTRICTION_ID } from '@/utils/constants';
import { AppCreateType } from '@/pages/apps/create-app/constants';

interface NewAppData {
  appName?: string;
  appSlug?: string;
  appDescription?: string;
  shouldCreateWithTable?: boolean;
  appOrigin?: AppCreateType;
  shouldCreateDefaultUserRoles?: boolean;
}

interface DuplicateAppData extends NewAppData {
  appCopyRecords: boolean;
  appCopyTasks: boolean;
  appPausedTasks: boolean;
  copyAppId: string;
}

interface AppData {
  id: string;
  appName: string;
  appSlug: string;
  appDescription: string;
}

export interface CreateAppFromSampleData {
  appId: string | number | undefined;
  templateSlug: string;
  appDescription: string;
  appCreatedAutomatically?: boolean;
}

async function createApp(
  accountId: string,
  {
    appName,
    appSlug,
    appDescription,
    appOrigin,
    shouldCreateWithTable,
    shouldCreateDefaultUserRoles = false
  }: NewAppData
) {
  const endpoint = `/v1/accounts/${accountId}/applications${
    appOrigin ? `?origin=${appOrigin}` : ''
  }`;

  const newAppData = {
    settings: {
      v3_beta: false
    },
    name: appName,
    slug: appSlug,
    description: appDescription,
    shouldCreateWithTable,
    shouldCreateDefaultUserRoles
  };

  const { data } = await axios.post(endpoint, newAppData, {
    withCredentials: true
  });
  return data;
}

async function createAppFromSample(
  { appId, templateSlug, appDescription, appCreatedAutomatically }: CreateAppFromSampleData,
  accountId: string,
  accountSlug: string
) {
  const params = {
    app_id: appId,
    account_slug: accountSlug,
    app_description: appDescription,
    v3: false
  };

  const endpoint = `/v1/accounts/${accountId}/template?origin=${
    appCreatedAutomatically ? AppCreateType.Auto : AppCreateType.Sample
  }&template_slug=${templateSlug}`;
  const { data } = await axios.get<{ app: { slug: string; objects: { key: string }[] } }>(
    endpoint,
    {
      params,
      withCredentials: true
    }
  );

  return data;
}

async function duplicateApp(accountId: string, appData: DuplicateAppData) {
  const { appName, appCopyRecords, appCopyTasks, appPausedTasks, copyAppId } = appData;
  const endpoint = `/v1/accounts/${accountId}/apps/${copyAppId}/copy`;

  const { data } = await axios.get(endpoint, {
    withCredentials: true,
    params: {
      origin: 'copy',
      app_name: appName && appName.trim(),
      copy_records: appCopyRecords,
      v3: false,
      copy_tasks: appCopyTasks,
      pause_tasks: appPausedTasks
    }
  });
  return data;
}

async function updateApp(accountId: string, appData: AppData) {
  const { appName, appSlug, appDescription, id } = appData;
  const endpoint = `/v1/accounts/${accountId}/apps/${appData.id}`;

  const updatedAppData = {
    id,
    account_id: accountId,
    app_name: appName.trim(),
    app_slug: appSlug,
    app_description: appDescription.trim()
  };

  const { data } = await axios.put(endpoint, updatedAppData, {
    withCredentials: true
  });

  return data;
}

async function deleteApp(accountId: string, appId: string) {
  const endpoint = `/v1/accounts/${accountId}/applications/${appId}/delete`;

  const { data } = await axios.post(
    endpoint,
    {},
    {
      withCredentials: true
    }
  );

  return data;
}

async function sortApps(accountId: string, appsIds: string[]) {
  const endpoint = `/v1/accounts/${accountId}/apps/sort`;

  const { data } = await axios.post(
    endpoint,
    { order: appsIds },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useAppMutation() {
  const session = useSession();
  const queryClient = useQueryClient();
  const accountRestrictions = useAccountRestrictions();
  const [dismissedBannersLocalStorage, setDismissedBannersLocalStorage] = useLocalStorage(
    DISMISS_BANNER_SESSION_KEY,
    ['']
  );

  const createAppMutation = useMutation({
    mutationFn: (newAppData: NewAppData) => createApp(session.account.id, newAppData)
  });

  const createAppFromSampleMutation = useMutation({
    mutationFn: (newAppData: CreateAppFromSampleData) =>
      createAppFromSample(newAppData, session.account.id, session.account.slug)
  });

  const duplicateAppMutation = useMutation({
    mutationFn: (newAppData: DuplicateAppData) => duplicateApp(session.account.id, newAppData),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.apps.applications] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
    }
  });

  const updateAppMutation = useMutation({
    mutationFn: (appData: AppData) => updateApp(session.account.id, appData),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.apps.applications] });
    }
  });

  const sortAppsMutation = useMutation({
    mutationFn: (appsIds: string[]) => sortApps(session.account.id, appsIds)
  });

  const deleteAppMutation = useMutation({
    mutationFn: (appId: string) => deleteApp(session.account.id, appId),
    onMutate: async () => {
      // The banner is saved in localStorage when the user reaches the limit and dismisses it. If the user removes an application, the dismissed banner is moved to sessionStorage. The banner will be only visible in a new session after reaching the limit again.
      if (
        accountRestrictions?.reachedAppLimit &&
        dismissedBannersLocalStorage.includes(RESTRICTION_ID.reachedAppLimit)
      ) {
        setDismissedBannersLocalStorage(
          dismissedBannersLocalStorage.filter(
            (banner: string) => banner !== RESTRICTION_ID.reachedAppLimit
          )
        );
        sessionStorage.setItem(
          DISMISS_BANNER_SESSION_KEY,
          JSON.stringify([RESTRICTION_ID.reachedAppLimit])
        );
      }
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [queryKeys.apps.applications] });
      void queryClient.invalidateQueries({ queryKey: [queryKeys.account] });
    }
  });

  return {
    createApp: createAppMutation,
    createAppFromSample: createAppFromSampleMutation,
    duplicateApp: duplicateAppMutation,
    updateApp: updateAppMutation,
    deleteApp: deleteAppMutation,
    sortApps: sortAppsMutation
  };
}
