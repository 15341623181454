import { Controller, type Control, type FormState, type UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Card, Input, InputWithDisabledText, Select } from '@knack/asterisk-react';

import { type Account } from '@/types/account';
import { type Session } from '@/types/session';
import { getFlagValue } from '@/utils/flagsmith';
import { FLOWS_LANGUAGES, getFlowsTimezones } from '@/utils/flows';
import { FormControl } from '@/components/ui/FormControl';

type FormValues = {
  name: string;
  slug: string;
  settings: {
    flows: {
      timezone: string;
      language: string;
    };
  };
};

interface AccountInfoFormProps {
  formState: FormState<FormValues>;
  register: UseFormRegister<FormValues>;
  session: Session;
  account?: Account;
  control: Control<FormValues>;
}

export function AccountInfoForm({
  formState,
  register,
  session,
  account,
  control
}: AccountInfoFormProps) {
  const [t] = useTranslation();
  const { errors, isSubmitting } = formState;
  const isFlowsEnabled = getFlagValue('albato_flows');

  return (
    <>
      <Card className="flex flex-col p-6 sm:p-6">
        <h2 className="mb-6 text-xl text-emphasis">{t('components.account.account_info')}</h2>
        <div className="flex flex-col gap-6">
          <FormControl className="w-full">
            <FormControl.Label htmlFor="account-name" intent={errors.name && 'destructive'}>
              {t('components.account.account_name')}
            </FormControl.Label>
            <Input
              disabled={isSubmitting}
              id="account-name"
              data-testid="account-overview-organization-name"
              title={t('components.account.title')}
              type="text"
              intent={errors.name && 'destructive'}
              placeholder={t('components.account.placeholder_account_name')}
              defaultValue={session.account.name}
              {...register('name')}
            />
            <FormControl.Message type={errors.name && 'error'} className="text-subtle">
              {errors?.name?.message
                ? t('components.account.name_required')
                : t('components.account.name_helper_text')}
            </FormControl.Message>
          </FormControl>
          <div className="flex w-full justify-around">
            <FormControl className="w-full">
              <FormControl.Label intent={errors.slug && 'destructive'} htmlFor="account-url">
                {t('components.account.url')}
              </FormControl.Label>
              <InputWithDisabledText
                disabled={isSubmitting}
                id="account-url"
                data-testid="account-overview-slug-url"
                disabledTextRight={`.${import.meta.env.PUBLIC_LIVE_APP_SUFFIX}`}
                type="text"
                intent={errors.slug && 'destructive'}
                title={t('components.account.url')}
                placeholder={t('components.account.placeholder_account_slug')}
                defaultValue={session.account.slug}
                {...register('slug')}
              />
              <FormControl.Message type={errors.slug && 'error'} className="text-subtle">
                {errors?.slug?.message
                  ? t('components.account.url_allowed_characters')
                  : t('components.account.url_helper_text')}
              </FormControl.Message>
            </FormControl>
          </div>
        </div>
      </Card>

      {isFlowsEnabled && (
        <Card className="flex flex-col p-6 sm:p-6">
          <div className="mb-6">
            <h2 className="text-xl text-emphasis">{t('components.account.flows_settings')}</h2>
            <div className="text-sm text-subtle">
              {t('components.account.flows_settings_helper_text')}
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <FormControl className="w-full">
              <FormControl.Label htmlFor="flows-timezone">
                {t('components.account.flows_settings_timezone')}
              </FormControl.Label>
              <Controller
                control={control}
                name="settings.flows.timezone"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    disabled={isSubmitting}
                    defaultValue={account?.settings?.flows.timezone}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger
                      id="flows-timezone"
                      placeholder={t('components.account.flows_settings_placeholder_timezone')}
                      className="w-full"
                    />
                    <Select.Content>
                      {getFlowsTimezones().map((timezone) => (
                        <Select.Item key={timezone.value} value={timezone.value}>
                          {timezone.label}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </FormControl>

            <FormControl className="w-full">
              <FormControl.Label htmlFor="flows-language">
                {t('components.account.flows_settings_language')}
              </FormControl.Label>
              <Controller
                control={control}
                name="settings.flows.language"
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    disabled={isSubmitting}
                    defaultValue={account?.settings?.flows.language}
                    onValueChange={field.onChange}
                  >
                    <Select.Trigger
                      id="flows-language"
                      placeholder={t('components.account.flows_settings_placeholder_language')}
                      className="w-full"
                    />
                    <Select.Content>
                      {FLOWS_LANGUAGES.map((language) => (
                        <Select.Item key={language.value} value={language.value}>
                          {language.label}
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </Card>
      )}
    </>
  );
}
